import App from '@/app.vue';
import '@/utils/date';
import axios from 'axios';
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import VueAxios from 'vue-axios';
import {Route} from 'vue-router';
import router from './router';
import store from './store';
import VModal from 'vue-js-modal';
import './style/main.scss';

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueAxios, axios);

let currentRoute = window.document.location.hash;
let userIdEnd = currentRoute.indexOf('/', 2) < 0 ? currentRoute.length : currentRoute.indexOf('/', 2);
let userId = currentRoute.substring(2, userIdEnd);

Vue.use(VueAnalytics, {
    id: 'UA-114190735-1',
    router,
    fields: {
        userId
    },
    set: [
        {
            field: 'anonymizeIp',
            value: 'true'
        }
    ],
    autoTracking: {
        shouldRouterUpdate(to: Route, from: Route): boolean {
            return to.path !== from.path;
        }
    },
    disabled: process.env.NODE_ENV !== 'production' || window.document.cookie.indexOf('ga-opt-out=true') !== -1
});

Vue.use(VModal, { dialog: true });

Vue.axios.defaults.baseURL = process.env.VUE_APP_CAMPAIGN_URL;

new App({
    router,
    store
}).$mount('#app');
