declare global {
    interface Date {
        toDateString(this: Date): string;

        toReadableDateString(this: Date): string;
    }

    interface Number {
        toPaddedString(this: number, stringLength: number): string;
    }
}

// when merge these namespaces the date
// returned from toDate would be a
// date just with toDateString method
// this is a bug of IntelliJ!!!
// tslint:disable-next-line:no-mergeable-namespace
declare global {
    interface String {
        toDate(): Date | null;
    }
}

Object.defineProperty(Date.prototype, 'toDateString', {
    value(this: Date): string {
        return `${this.getFullYear().toPaddedString(4)}-${(this.getMonth() + 1).toPaddedString(2)}-${this.getDate().toPaddedString(2)}`;
    },
    writable: false,
    configurable: false,
    enumerable: false
});

Object.defineProperty(Date.prototype, 'toReadableDateString', {
    value(this: Date): string {
        return `${this.getDate().toPaddedString(2)}.${(this.getMonth() + 1).toPaddedString(2)}.${this.getFullYear().toPaddedString(4)}`;
    },
    writable: false,
    configurable: false,
    enumerable: false
});

Object.defineProperty(String.prototype, 'toDate', {
    value(this: string): Date | null {
        let result = /^(\d{4})-(\d{2})-(\d{2})$/.exec(this);
        if (!result) {
            return null;
        }
        return new Date(parseInt(result[1]), parseInt(result[2]) - 1, parseInt(result[3]));
    },
    writable: false,
    configurable: false,
    enumerable: false
});

Object.defineProperty(Number.prototype, 'toPaddedString', {
    value(this: Date, stringLength: number): string {
        let value = this.toString();
        while (value.length < stringLength) {
            value = `0${value}`;
        }
        return value;
    },
    writable: false,
    configurable: false,
    enumerable: false
});

export {};
