import {
    ApplicationConfiguration,
    IBANSubscriptionUpdate,
    PortalSettings,
    PublicDeal,
    PublicVisit,
    SubscriptionConfirmation,
    SubscriptionFieldValue
} from '@/model/backend/campaign-api';
import Vue from 'vue';
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';

@Module
export default class Visit extends VuexModule {
    private _visitEntity: PublicVisit | null = null;
    private _applicationConfigurationEntity: ApplicationConfiguration | null = null;
    private _theme: string | null = null;
    private _settings: PortalSettings;

    public get visit(): PublicVisit {
        return this._visitEntity!;
    }

    public get settings() {
        return this._settings || {
            htmlPlaceholder: false,
            showEmotion: true,
            showProductImage: true,
            showOfferImage: false,
            showScrollButton: true,
            readMoreText: 'weiter lesen',
            thankYouText: 'Dein Dankeschön von',
            useV1Images: false
        };
    }

    public get theme() {
        return this._theme;
    }

    public get deal(): PublicDeal | null {
        if (!this.visit) {
            return null;
        }
        let offerId = this.context.getters.offerId;
        for (let deal of this.visit.deals) {
            if (deal.offerId === offerId) {
                return deal;
            }
        }
        return null;
    }

    public get remainingOffers(): string[] {
        if (!this._visitEntity) {
            return [];
        }
        return this._visitEntity.offerIds.concat(this._visitEntity.featuredOfferIds)
            .filter(offerId => this._visitEntity!.subscriptionAndExternalDealIds.indexOf(offerId) === -1);
    }

    public get appConfig(): ApplicationConfiguration {
        return this._applicationConfigurationEntity!;
    }

    @Action
    public async loadVisit(visitId: string): Promise<PublicVisit | null> {
        if (this._visitEntity) {
            return this._visitEntity;
        }
        try {
            return (await Vue.axios.get(`/visit/${visitId}`)).data;
        } catch (e) {
            return null;
        }
    }

    @Action({commit: 'setVisit'})
    public async reloadVisit(): Promise<PublicVisit> {
        return (await Vue.axios.get(`/visit/${this.visit.id}`)).data;
    }

    @Action({commit: 'setAppConfig'})
    public async loadAppConfig(visitId: string): Promise<ApplicationConfiguration | null> {
        if (this._applicationConfigurationEntity) {
            return this._applicationConfigurationEntity;
        }
        try {
            return (await Vue.axios.get(`/application/${visitId}`)).data;
        } catch (e) {
            return null;
        }
    }

    @Action({commit: 'setVisit'})
    public async submitSubscription(fields: SubscriptionFieldValue[]): Promise<PublicVisit> {
        let payload: SubscriptionConfirmation = {
            offerId: this.context.getters.offerId,
            fields
        };
        return (await Vue.axios.put(`/visit/${this.visit.id}/subscription`, payload)).data;
    }

    @Action({commit: 'setVisit'})
    public async upgradeSubscription(): Promise<PublicVisit> {
        return (await Vue.axios.put(`/visit/${this.visit.id}/subscription/${this.context.getters.offerId}/upgrade`)).data;
    }

    @Action({commit: 'setVisit'})
    public async addIbanToSubscription(payload: IBANSubscriptionUpdate): Promise<PublicVisit> {
        return (await Vue.axios.put(`/visit/${this.visit.id}/subscription/${this.context.getters.offerId}/iban`, payload)).data;
    }

    @Mutation
    public setVisit(visit: PublicVisit): void {
        this._visitEntity = visit;
    }

    @Mutation
    public setAppConfig(appConfig: ApplicationConfiguration): void {
        let theme = appConfig.portalConfiguration.theme || 'light';
        if (process.env.VUE_APP_PRIMARY_THEME) {
            theme = process.env.VUE_APP_PRIMARY_THEME;
        } else {
            let themeCssLink = window.document.getElementById('theme-link') as HTMLLinkElement;
            if (!themeCssLink) {
                themeCssLink = window.document.createElement('link');
            }
            themeCssLink.href = `/css/theme-${theme}.css`;
            themeCssLink.rel = 'stylesheet';
            themeCssLink.id = 'theme-link';
            window.document.head.appendChild(themeCssLink);
        }
        this._theme = theme;
        this._settings = appConfig.portalConfiguration.portalSettings;
        this._applicationConfigurationEntity = appConfig;
    }
}
