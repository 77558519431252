import store from '@/store';
import Vue from 'vue';
import Router, {RawLocation, Route} from 'vue-router';

Vue.use(Router);

let router = new Router({
    routes: [
        {
            name: 'form-view',
            path: '/:visitId/subscription/:offerId',
            component: () => import('./views/form-view.vue')
        },
        {
            name: 'upgrade-view',
            path: '/:visitId/upgrade/:offerId',
            component: () => import('./views/upgrade-view.vue')
        },
        {
            name: 'iban-view',
            path: '/:visitId/iban/:offerId',
            component: () => import('./views/iban-view.vue')
        },
        {
            name: 'thank-you-view',
            path: '/:visitId/thank-you/:offerId',
            component: () => import('./views/thank-you-view.vue')
        },
        {
            name: 'page-view',
            path: '/:visitId/:lastPage/:offerId/page/:pageId',
            component: () => import('./views/page-view.vue')
        },
        {
            name: 'external-page-view',
            path: '/page/:pageId',
            component: () => import('./views/page-view.vue')
        },
        {
            name: 'landing-page-view',
            path: '/:visitId/landing-page',
            component: () => import('./views/landing-page-view.vue'),
            meta: {
                appFooterHidden: true
            }
        },
        {
            name: 'external-landing-page-view',
            path: '/:visitId/landing-page/:snippetId/:load?',
            component: () => import('./views/landing-page-view.vue'),
            meta: {
                appFooterHidden: true
            }
        },
        {
            name: 'error',
            path: '*',
            component: () => import('./views/error-view.vue')
        }
    ]
});

router.beforeEach(beforeEach);

async function beforeEach(to: Route, from: Route, next: (to?: RawLocation | false | void) => void) {
    if (to.params.hasOwnProperty('visitId')) {
        let visit = await store.dispatch('loadVisit', to.params.visitId);
        if (!visit) {
            next('/');
            return;
        }
        store.commit('setVisit', visit);
        await store.dispatch('loadAppConfig', to.params.visitId);
    }
    if (to.params.hasOwnProperty('offerId')) {
        let offer = await store.dispatch('loadOffer', to.params.offerId);
        if (!offer) {
            next('/');
            return;
        }
        store.commit('setOffer', offer);
    }
    next();
}

export default router;
