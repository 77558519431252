var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.appConfig &&
    _vm.appConfig.snippetConfiguration &&
    _vm.appConfig.snippetConfiguration.shopImage
    ? _c("div", { staticClass: "app-header-wrapper" }, [
        _c("div", { staticClass: "app-header" }, [
          _c("div", { staticClass: "container" }, [
            _c("span", { staticClass: "slogan" }, [
              _vm._v(_vm._s(_vm.settings.thankYouText) + " ")
            ]),
            _c("img", {
              staticClass: "logo-shop",
              attrs: {
                src: _vm.appConfig.snippetConfiguration.shopImage,
                alt: "Shop-Logo"
              }
            })
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }