



































import {Component, Prop} from '@/decorators';
import {PublicPagesObject} from '@/model/backend/campaign-api';
import Vue from 'vue';

@Component({})
    export default class AppFooter extends Vue {

        private pageContent: string = '';

    @Prop({required: true})
    private pages: PublicPagesObject | null;

    @Prop({required: false, default: 'container'})
    private wrapperClass: string;

        private openLink(pageId: string) {
            this.$http.get(`/contentPage/${pageId}`)
                .then(response => {
                    this.pageContent = response.data.content;
                    this.$modal.show('page-content');

                    this.$nextTick(() => {
                        const refContent = this.$refs.content as HTMLDivElement;
                        if (refContent) {
                            // add _blank to all links
                            for (let item of refContent.getElementsByTagName('a')) {
                                if (item.href && item.href.endsWith('/#/google-analytics-opt-out')) {
                                    item.addEventListener('click', event => {
                                        event.preventDefault();
                                        this.$ga.disable();
                                        let in20Years = new Date(new Date().getTime() + 630720000000);
                                        window.document.cookie = `ga-opt-out=true; expires=${in20Years}; path=/`;
                                    });
                                } else {
                                    item.target = '_blank';
                                }
                            }
                        }
                    });
                });
        }

        private closeWindow() {
            this.$modal.hide('page-content');
        }
    }
