var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$route.name, _vm.theme], attrs: { id: "app" } },
    [
      _c("app-header"),
      _c("router-view", { ref: "currentView" }),
      _vm.showFooter
        ? _c("app-footer", { attrs: { pages: _vm.offer.pages } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }