import Offer from '@/store/offer';
import Visit from '@/store/visit';
import Vue from 'vue';
import Vuex, {Store} from 'vuex';

Vue.use(Vuex);

export default new Store({
    state: {},
    modules: {
        visit: Visit,
        offer: Offer
    },
    strict: true
});
