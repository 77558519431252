







import AppFooter from '@/components/app-footer.vue';
import AppHeader from '@/components/app-header.vue';
import {Component} from '@/decorators';
import Vue from 'vue';
import {Getter} from 'vuex-class';
import {PublicSubscriptionPortalOffer} from '@/model/backend/campaign-api';

@Component({
    components: {AppFooter, AppHeader}
})
export default class App extends Vue {

    @Getter
    private offer: PublicSubscriptionPortalOffer;

    @Getter
    private theme: string | null;

    private isDev(): boolean {
        return process.env.VUE_APP_DEV_MODE === 'true';
    }

    private gotoOldPortal(): void {
        if (!this.$refs.currentView || !(this.$refs.currentView as any).oldPortalUrl) {
            window.alert('Es wurde kein Link zum alten Portal gefunden!');
            return;
        }
        window.open((this.$refs.currentView as any).oldPortalUrl);
    }

    private get showFooter() {
        if (this.$route.meta && this.$route.meta.appFooterHidden) {
            return false;
        }
        return this.offer && this.offer.pages;
    }
}
