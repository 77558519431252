












import {Component} from '@/decorators';
import {ApplicationConfiguration, PortalSettings} from '@/model/backend/campaign-api';
import Vue from 'vue';
import {Getter} from 'vuex-class';

@Component({})
export default class AppFooter extends Vue {
    @Getter
    private appConfig: ApplicationConfiguration;

    @Getter
    private settings: PortalSettings;
}
