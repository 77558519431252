var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          staticClass: "vue-dialog",
          attrs: {
            height: "310",
            name: "page-content",
            scrollable: "",
            width: "70%"
          }
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", {
              ref: "content",
              staticClass: "dialog-c-text",
              domProps: { innerHTML: _vm._s(_vm.pageContent) }
            }),
            _c("div", { staticClass: "vue-dialog-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "vue-dialog-button",
                  staticStyle: { flex: "1 1 100%" },
                  attrs: { type: "button" },
                  on: { click: _vm.closeWindow }
                },
                [_vm._v("Schließen\n                ")]
              )
            ])
          ])
        ]
      ),
      _vm.pages && _vm.$route.name
        ? _c("footer", { staticClass: "footer app-footer" }, [
            _c("div", { class: _vm.wrapperClass }, [
              _c("div", { staticClass: "footer-links" }, [
                _vm.pages.imprintId
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openLink(_vm.pages.imprintId)
                            }
                          }
                        },
                        [_vm._v("Impressum")]
                      )
                    ])
                  : _vm._e(),
                _vm.pages.privacyPolicyId
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openLink(_vm.pages.privacyPolicyId)
                            }
                          }
                        },
                        [_vm._v("Datenschutz")]
                      )
                    ])
                  : _vm._e(),
                _vm.pages.businessTermsId
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openLink(_vm.pages.businessTermsId)
                            }
                          }
                        },
                        [_vm._v("AGB")]
                      )
                    ])
                  : _vm._e(),
                _vm.pages.revocationPolicyId
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openLink(_vm.pages.revocationPolicyId)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.pages.revocationPolicyLabel))]
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }