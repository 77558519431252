import 'reflect-metadata';
import Vue, {ComputedOptions, PropOptions} from 'vue';
import {createDecorator, VueDecorator} from 'vue-class-component';

export {Component, Watch, Emit, Inject, Model, Provide} from 'vue-property-decorator';

export function Prop(target: object, propertyKey: string | symbol): void;
export function Prop(options?: PropOptions): PropertyDecorator;
export function Prop(types: Constructor[]): PropertyDecorator;
export function Prop(arg1?: object | PropOptions | Constructor[], arg2?: string | symbol): PropertyDecorator | void {
    if (arg1 && arg2) {
        let type = Reflect.getMetadata('design:type', arg1, arg2);
        let options: PropOptions = {
            type,
            required: type !== Boolean
        };
        createPropDecorator(options)(arg1 as Vue, arg2 as string);
        return;
    }
    return (target, propertyKey) => {
        let options: PropOptions;
        if (Array.isArray(arg1)) {
            options = {
                type: arg1
            };
        } else {
            options = arg1 as PropOptions || {};
            if (!options.type) {
                let type = Reflect.getMetadata('design:type', target, propertyKey);
                options.type = type;
                if (!options.hasOwnProperty('required')) {
                    options.required = type !== Boolean;
                }
            }
        }
        createPropDecorator(options)(target as Vue, propertyKey as string);
    };
}

function createPropDecorator(options: PropOptions): VueDecorator {
    return createDecorator((componentOptions, key) => {
        let props = componentOptions.props;
        if (!props) {
            props = componentOptions.props = {};
        }
        (props as any)[key] = options;
    });
}

export const PreventCache = createDecorator((options, key) => {
    let computedElement: (() => any) | ComputedOptions<any> = options.computed![key];
    if (typeof computedElement === 'function') {
        options.computed![key] = {
            get: computedElement,
            cache: false
        };
    } else {
        computedElement.cache = false;
    }
});

export declare type Constructor = new(...args: any[]) => any;
