import {PublicSubscriptionPortalOffer, PublicVisit, TeaserOffer} from '@/model/backend/campaign-api';
import Vue from 'vue';
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';

@Module
export default class Offer extends VuexModule {
    private _offerEntity: PublicSubscriptionPortalOffer | null = null;

    private _offers: Record<string, PublicSubscriptionPortalOffer> = {};

    private _teaserOffers: Record<string, TeaserOffer> = {};

    private visit: PublicVisit;

    public get offer(): PublicSubscriptionPortalOffer {
        return this._offerEntity!;
    }

    public get offerId(): string {
        if (!this._offerEntity) {
            return '';
        }
        return this._offerEntity!.id;
    }

    public get upgraded(): boolean {
        if (!this.visit) {
            return false;
        }
        let deal = this.visit.deals.find(current => current.offerId === this.offerId);
        if (deal) {
            return deal.upgraded;
        }
        return false;
    }

    public get showFurtherOffers(): boolean {
        // breuninger only
        if (this.visit && this.visit.snippetId && this.visit.snippetId === '5d5d0d63b67f570001c8fdfb') {
            return !this.visit.deals || this.visit.deals.length <= 0;
        }
        return true;
    }

    @Action
    public async loadOffer(offerId: string): Promise<PublicSubscriptionPortalOffer | null> {
        if (this._offers.hasOwnProperty(offerId)) {
            return this._offers[offerId];
        }
        try {
            let offer = (await Vue.axios.get(`/offer/portal/${offerId}`)).data;
            this.context.commit('pushOffer', offer);
            return offer;
        } catch (e) {
            return null;
        }
    }

    @Action
    public async loadTeaserOffer(offerId: string): Promise<TeaserOffer | null> {
        if (this._teaserOffers.hasOwnProperty(offerId)) {
            return this._teaserOffers[offerId];
        }
        try {
            let offer = (await Vue.axios.get(`/offer/featured/${offerId}`)).data;
            this.context.commit('pushTeaserOffer', offer);
            return offer;
        } catch (e) {
            return null;
        }
    }

    @Mutation
    public setOffer(offer: PublicSubscriptionPortalOffer): void {
        this._offerEntity = offer;
    }

    @Mutation
    public pushOffer(offer: PublicSubscriptionPortalOffer): void {
        this._offers[offer.id] = offer;
    }

    @Mutation
    public pushTeaserOffer(offer: TeaserOffer): void {
        this._teaserOffers[offer.id] = offer;
    }
}
