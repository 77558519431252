/* tslint:disable */

// Generated using typescript-generator version 2.8.449 on 2019-08-12 13:51:34.

export interface AdvertiserData extends ClientData {
    exclusions: PublisherData[];
}

export interface ApplicationConfigurationData extends ApplicationConfigurationEditData, IdEntity {
}

export interface CampaignData extends CampaignEditData, IdEntity {
    createdBy: Auditor;
    created: Date;
    lastModifiedBy: Auditor;
    lastModified: Date;
    advertiserData: AdvertiserData;
}

export interface ClientData extends ClientEditData, IdEntity {
    users: UserData[];
}

export interface ContactData {
    id: string;
    salutation: string;
    firstName: string;
    lastName: string;
    email: string;
    street: string;
    streetNumber: string;
    zipCode: string;
    city: string;
    phone: string;
    mobile: string;
    customerId: string;
    dateOfBirth: string;
    yearOfBirth: number;
    invalidFields: string[];
}

export interface ContentPageData extends ContentPageEditData {
    id: string;
}

export interface CustomerGroupData extends CustomerGroupEditData, IdEntity {
}

export interface DealData extends DealEditData {
    submitted: Date;
    dealContact: ContactData;
    offer: SimpleOffer;
    voucherCode: string;
    showUpgrade: boolean;
    showBank: boolean;
    doiConfirm: DoiConfirmData;
}

export interface DoiConfirmData {
    ip: string;
    timestamp: number;
}

export interface ExternalOfferData extends OfferData {
    '@type': 'VoucherExternalOfferData';
    thumbImage: AssetReference;
    thumbImageUrl: string;
    offerLink: string;
    showInFrame: boolean;
    shortTeaser: TeaserObject;
}

export interface FeedReferenceData {
    feedId: string;
    lastModified: Date;
    manualOverride: boolean;
    feedType: FeedType;
}

export interface FilterData {
    filters: Filter<any>[];
    minimumCorrectFilter: number;
}

export interface BooleanFilter extends Filter<boolean> {
    type: 'boolean';
    value: boolean;
}

export interface DateFilter extends Filter<Date> {
    type: 'date';
    from: Date;
    to: Date;
}

export interface Filter<T> {
    type: 'boolean' | 'date' | 'null' | 'number' | 'string';
    propertyName: string;
}

export interface NullFilter extends Filter<any> {
    type: 'null';
    nullValue: boolean;
}

export interface NumberFilter extends Filter<number> {
    type: 'number';
    min: number;
    max: number;
}

export interface StringFilter extends Filter<string> {
    type: 'string';
    value: string;
    filterType: StringFilterType;
    ignoreCase: boolean;
}

export interface IdEntity {
    id: string;
}

export interface InstantCheckData {
    matchvn: string;
    matchnn: string;
    matchstr: string;
    groups: string;
    address_ok: boolean;
    age: string;
}

export interface MatchData {
    customerGroups: string[];
    matchvn: string;
}

export interface NewsletterProviderData extends NewsletterProviderEditData {
    id: string;
}

export interface NewsletterSubscriptionData {
    emailSent: Date;
    emailAccepted: Date;
    bounce: Date;
    newsletterProviderData: NewsletterProviderData;
}

export interface OfferData {
    '@type': 'VoucherExternalOfferData' | 'VoucherSubscriptionData' | 'VoucherOfferData';
    id: string;
    campaign: CampaignData;
    type: OfferType;
    preview: boolean;
    active: boolean;
    provision: number;
    customerGroups: CustomerGroupData[];
    ranking: number;
    headline: string;
    offerBody: string;
    confirmationEmailText: string;
    requestButtonText: string;
    feedReference: FeedReferenceData;
    validZipCodes: string[];
    start: Date;
    end: Date;
    limit: number;
    currentLimitation: number;
}

export interface PortalSettings {
    htmlPlaceholder: boolean;
    showEmotion: boolean;
    showProductImage: boolean;
    showOfferImage: boolean;
    showScrollButton: boolean;
    readMoreText: string;
    thankYouText: string;
    useV1Images: boolean;
}

export interface PublisherData extends ClientData, IdEntity {
    provisionPercentage: number;
    trafficCategory: TrafficCategory;
}

export interface SnippetData extends SnippetEditData, IdEntity {
    publisher: PublisherData;
}

export interface SnippetGiftData {
    visible: boolean;
    headline: string;
    body: string;
    buttonCaption: string;
    tooltipText: string;
    image: AssetReference;
}

export interface SnippetLandingPageData {
    teaserImage: AssetReference;
    teaserHeadline: string;
    teaserText: string;
    teaserButtonCaption: string;
    landingPageVersion: string;
}

export interface SnippetLayoutData {
    resourceVersion: string;
    portalResource: string;
    voucherPortalResource: string;
    themeResource: string;
    portalTheme: string;
    portalSettings: PortalSettings;
    snippetLayoutGift: SnippetGiftData;
    snippetLayoutOffer: SnippetOfferData;
    snippetModal: SnippetModalData;
    landingPage: SnippetLandingPageData;
}

export interface SnippetModalData {
    enabled: boolean;
    opened: boolean;
    closeOnClickOutside: boolean;
    cloneContent: boolean;
    buttonCaption: string;
}

export interface SnippetOfferData {
    header: SnippetOfferHeaderData;
    displayMode: SnippetOfferDisplayMode;
    displaySize: DisplaySize;
    buttonCaption: string;
    tooltipText: string;
    totalCount: number;
    loadMore: SnippetOfferLoadMoreData;
}

export interface SnippetOfferHeaderData {
    visible: boolean;
    body: string;
    image: AssetReference;
}

export interface SnippetOfferLoadMoreData {
    enabled: boolean;
    initialLoadCount: number;
    caption: string;
}

export interface SubscriptionFieldValue {
    field: SubscriptionFormField;
    value: string;
}

export interface SubscriptionOfferData extends OfferData {
    '@type': 'VoucherSubscriptionData';
    formFields: { [index: string]: FormFieldConfig };
    revocationPolicyPageId: string;
    businessTermsPageId: string;
    privacyPolicyPageId: string;
    imprintPageId: string;
    thumbBody: string;
    thumbImage: AssetReference;
    thumbImageUrl: string;
    offerImage: AssetReference;
    logoImage: AssetReference;
    offerSubtitle: string;
    upgradeSubscriptionPage: UpgradeSubscriptionPageEditData;
    ibanSubscriptionPage: IBANSubscriptionPageEditData;
    thankYouSubscriptionPage: ThankYouSubscriptionPageEditData;
    buttonText: string;
    buttonColor: string;
    offerBodyCollapsible: boolean;
    prefilledFieldsBehaviour: PrefilledFieldsBehaviour;
    defaultDeliveryDate: number;
    earliestSelectableDeliveryDate: number;
    doiCode: string;
    shortTeaser: TeaserObject;
    form: FormObject;
    iban: IbanObject;
    upgrade: UpgradeObject;
    thankYou: ThankYouObject;
}

export interface UserData extends UserEditData, IdEntity {
    keycloakId: string;
    enabled: boolean;
}

export interface VisitData extends VisitEditData, IdEntity {
    publicVisitId: string;
}

export interface VisitOverviewData {
    id: string;
    dated: Date;
    firstName: string;
    lastName: string;
    email: string;
    publisherName: string;
    snippetName: string;
    clicks: number;
    subscriptionCount: number;
    externalCount: number;
    voucherCount: number;
    newsletterCount: number;
}

export interface VisitStatisticData {
    all: VisitStatisticDataRow[];
    withDeal: VisitStatisticDataRow[];
    period: StatisticPeriod;
}

export interface VisitStatisticDataRow {
    date: Date;
    value: number;
}

export interface VoucherCodeData extends VoucherCodeEditData {
    id: string;
    voucherId: string;
}

export interface VoucherOfferData extends OfferData {
    '@type': 'VoucherOfferData';
    offerImage: AssetReference;
    benefitDescription: string;
    benefitValue: string;
    redeemLink: string;
    logoImage: AssetReference;
    showInFrame: boolean;
    codeMaxRedeemable: number;
    withCodes: boolean;
}

export interface CategoryData {
    id: string;
    name: string;
}

export interface CouponCategoryData {
    id: string;
    name: string;
    priority: number;
}

export interface CouponData {
    id: string;
    name: string;
    validFrom: Date;
    validTo: Date;
    lastUpdate: Date;
    type: string;
    value: number;
    unit: string;
    minimumOrderValue: string;
    onlyNewCustomer: boolean;
    couponCode: string;
    priority: number;
    favourite: boolean;
    couponUrl: string;
    details: string;
    merchant: MerchantData;
    categories: CouponCategoryData[];
    live: boolean;
    changed: boolean;
}

export interface MerchantData {
    id: string;
    name: string;
    logo: string;
}

export interface AdvertiserEditData extends ClientEditData {
    exclusions: PublisherData[];
}

export interface ApplicationConfigurationEditData {
    businessTermsPageId: string;
    privacyPolicyPageId: string;
    imprintPageId: string;
    contactMail: string;
    emailHeader: string;
    emailFooter: string;
    portalHeader: string;
    initialVoucherCount: number;
    maxVoucherCount: number;
    snippetLayout: SnippetLayoutData;
    active: boolean;
    description: string;
}

export interface CampaignEditData {
    name: string;
    partnerName: string;
    active: boolean;
    assets: AssetReference[];
    type: OfferType;
    featuredOffer: boolean;
    defaultProvision: number;
    planClickToConversation: number;
    planConfirmQuote: number;
    trafficCategories: TrafficCategory[];
}

export interface ClientEditData {
    name: string;
}

export interface ContentPageEditData {
    title: string;
    content: string;
    type: ContentType;
}

export interface CustomerGroupEditData {
    systemRefId: string;
    name: string;
    description: string;
}

export interface DealEditData {
    cancelled: boolean;
}

export interface ExternalOfferEditData extends OfferEditData {
    '@type': 'VoucherExternalOfferEditData';
    thumbImage: AssetReference;
    thumbImageUrl: string;
    offerLink: string;
    shortTeaser: TeaserObject;
    showInFrame: boolean;
}

export interface NewsletterProviderEditData {
    name: string;
    body: string;
    logoImage: AssetReference;
    cpl: number;
    active: boolean;
    start: Date;
    end: Date;
    doiCode: string;
    customerGroups: CustomerGroupData[];
}

export interface OfferEditData {
    '@type': 'VoucherExternalOfferEditData' | 'VoucherSubscriptionEditData' | 'VoucherOfferEditData';
    type: OfferType;
    preview: boolean;
    active: boolean;
    provision: number;
    customerGroupsIds: string[];
    ranking: number;
    headline: string;
    offerBody: string;
    confirmationEmailText: string;
    requestButtonText: string;
    feedReference: FeedReferenceData;
    validZipCodes: string[];
    start: Date;
    end: Date;
    limit: number;
}

export interface PublisherEditData extends ClientEditData {
    provisionPercentage: number;
    trafficCategory: TrafficCategory;
}

export interface SnippetEditData {
    systemRefId: string;
    name: string;
    snippetLayout: SnippetLayoutData;
    shopImage: AssetReference;
    voucherHeaderDesktop: AssetReference;
    voucherHeaderMobile: AssetReference;
}

export interface SubscriptionOfferEditData extends OfferEditData {
    '@type': 'VoucherSubscriptionEditData';
    formFields: { [index: string]: FormFieldConfig };
    revocationPolicyPageId: string;
    businessTermsPageId: string;
    privacyPolicyPageId: string;
    imprintPageId: string;
    thumbBody: string;
    thumbImage: AssetReference;
    thumbImageUrl: string;
    offerImage: AssetReference;
    logoImage: AssetReference;
    offerSubtitle: string;
    upgradeSubscriptionPage: UpgradeSubscriptionPageEditData;
    ibanSubscriptionPage: IBANSubscriptionPageEditData;
    thankYouSubscriptionPage: ThankYouSubscriptionPageEditData;
    buttonText: string;
    buttonColor: string;
    offerBodyCollapsible: boolean;
    prefilledFieldsBehaviour: PrefilledFieldsBehaviour;
    defaultDeliveryDate: number;
    earliestSelectableDeliveryDate: number;
    doiCode: string;
    shortTeaser: TeaserObject;
    form: FormObject;
    iban: IbanObject;
    upgrade: UpgradeObject;
    thankYou: ThankYouObject;
}

export interface IBANSubscriptionPageEditData {
    onlyActiveOnUpgrade: boolean;
    headline: string;
    subline: string;
    image: AssetReference;
    activationText: string;
    description: string;
    descriptionCollapsible: boolean;
    buttonAddText: string;
    buttonColor: string;
    forceIbanStep: boolean;
}

export interface ThankYouSubscriptionPageEditData {
    headline: string;
    image: AssetReference;
    description: string;
    buttonMoreVoucherText: string;
}

export interface UpgradeSubscriptionPageEditData {
    headline: string;
    subline: string;
    image: AssetReference;
    description: string;
    bulletPoints: string;
    descriptionCollapsible: boolean;
    buttonUpgradeText: string;
    buttonCancelText: string;
    upgradeButtonColor: string;
    cancelButtonColor: string;
    cancelDescription: string;
}

export interface UserEditData {
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
}

export interface VisitEditData {
    dated: Date;
    valid: boolean;
    subscriptionOfferIds: string[];
    voucherOfferIds: string[];
    deals: DealData[];
    newsletterSubscriptions: NewsletterSubscriptionData[];
    possibleNewsletterProvider: string[];
    businessTermsAccepted: Date;
    deviceType: DeviceType;
    identityId: string;
    snippetId: string;
    defaultContact: ContactData;
    portalProgress: PortalProgress;
}

export interface VoucherCodeEditData {
    voucherCode: string;
    redeemCount: number;
}

export interface VoucherOfferEditData extends OfferEditData {
    '@type': 'VoucherOfferEditData';
    offerImage: AssetReference;
    benefitDescription: string;
    benefitValue: string;
    redeemLink: string;
    logoImage: AssetReference;
    showInFrame: boolean;
    codeMaxRedeemable: number;
    withCodes: boolean;
}

export interface ButtonObject {
    caption: string;
    color: string;
}

export interface FormObject {
    formHeadline: string;
    emotionImage: AssetReference;
    productImage: AssetReference;
    details: SubscriptionDetails;
}

export interface IbanObject {
    image: AssetReference;
    headline: string;
    subHeadline: string;
    activationHeadline: string;
    description: string;
    addButton: ButtonObject;
    cancelButton: ButtonObject;
    cancelDescription: string;
    onlyActiveOnUpgrade: boolean;
    forceIbanStep: boolean;
}

export interface SubscriptionDetails {
    productTitle: string;
    bulletPoints: string;
    teaserText: string;
}

export interface ThankYouObject {
    image: AssetReference;
    headline: string;
    description: string;
    headlineVouchers: string;
    headlineOffers: string;
}

export interface UpgradeObject {
    image: AssetReference;
    headline: string;
    subHeadline: string;
    description: string;
    bulletPoints: string;
    upgradeButton: ButtonObject;
    cancelButton: ButtonObject;
    cancelDescription: string;
}

export interface PublicSubscriptionPortalOffer {
    id: string;
    name: string;
    headline: string;
    subtitle: string;
    logoImage: string;
    offerImage: string;
    form: PublicFormObject;
    upgrade: PublicUpgradeObject;
    iban: PublicIbanObject;
    thankYou: PublicThankYouObject;
    pages: PublicPagesObject;
}

export interface PublicFormObject {
    formHeadline: string;
    emotionImage: string;
    productImage: string;
    formFields: { [index: string]: PublicFormFieldConfig };
    prefilledFieldsBehaviour: PrefilledFieldsBehaviour;
    orderButton: ButtonObject;
    details: PublicSubscriptionDetails;
    defaultDeliveryDate: number;
    earliestSelectableDeliveryDate: number;
}

export interface PublicIbanObject {
    imageV1: string;
    image: string;
    headline: string;
    subHeadline: string;
    activationHeadline: string;
    description: string;
    addButton: ButtonObject;
    cancelButton: ButtonObject;
    cancelDescription: string;
    onlyActiveOnUpgrade: boolean;
    forceIbanStep: boolean;
}

export interface PublicPagesObject {
    revocationPolicyId: string;
    revocationPolicyLabel: string;
    businessTermsId: string;
    privacyPolicyId: string;
    imprintId: string;
}

export interface PublicSubscriptionDetails {
    productTitle: string;
    bulletPoints: string;
    teaserText: string;
    agreements: string;
}

export interface PublicThankYouObject {
    imageV1: string;
    image: string;
    headline: string;
    description: string;
    headlineVouchers: string;
    headlineOffers: string;
}

export interface PublicUpgradeObject {
    imageV1: string;
    image: string;
    headline: string;
    subHeadline: string;
    description: string;
    bulletPoints: string;
    upgradeButton: ButtonObject;
    cancelButton: ButtonObject;
    cancelDescription: string;
}

export interface PublicTeaserObject {
    headline: string;
    description: string;
    image: string;
    buttonCaption: string;
    benefit: BenefitObject;
}

export interface BenefitObject {
    value: string;
    description: string;
}

export interface TeaserObject {
    headline: string;
    description: string;
    image: AssetReference;
    buttonCaption: string;
    benefit: BenefitObject;
}

export interface ApplicationConfiguration {
    snippetConfiguration: SnippetConfiguration;
    portalConfiguration: PortalConfiguration;
}

export interface BusinessTermsConfirmation {
    portalNewsletter: boolean;
    newsletterIds: string[];
}

export interface ContentPagePublic {
    id: string;
    content: string;
    type: ContentType;
}

export interface IBANSubscriptionUpdate {
    depositor: string;
    iban: string;
}

export interface JavaScriptConfiguration {
    id: string;
    css: string;
    themeCss: string;
    js: string;
}

export interface Newsletter {
    id: string;
    name: string;
    body: string;
    logo: string;
}

export interface PortalConfiguration {
    businessTermsPageId: string;
    privacyPolicyPageId: string;
    imprintPageId: string;
    initialVoucherCount: number;
    maxVoucherCount: number;
    theme: string;
    portalSettings: PortalSettings;
}

export interface PublicDeal {
    offerId: string;
    upgraded: boolean;
    voucherCode: string;
    showUpgrade: boolean;
    showBank: boolean;
}

export interface PublicVisit {
    id: string;
    identity: string;
    valid: boolean;
    snippetId: string;
    contact: ContactData;
    dated: Date;
    teaserOfferIds: OfferConfig[];
    voucherIds: string[];
    featuredOfferIds: string[];
    offerIds: string[];
    subscriptionAndExternalDealIds: string[];
    newsletterIds: string[];
    voucherDealIds: string[];
    deals: PublicDeal[];
    portalProgress: PortalProgress;
}

export interface SnippetConfiguration {
    id: string;
    shopImage: string;
    voucherHeaderDesktop: string;
    voucherHeaderMobile: string;
    gift: SnippetGift;
    offer: SnippetOffer;
    modal: SnippetModal;
    landingPage: SnippetLandingPage;
}

export interface SnippetGift {
    visible: boolean;
    image: string;
    headline: string;
    body: string;
    buttonCaption: string;
    tooltipText: string;
}

export interface SnippetLandingPage {
    teaserImage: string;
    teaserHeadline: string;
    teaserText: string;
    teaserButtonCaption: string;
    landingPageUrl: string;
}

export interface SnippetModal {
    enabled: boolean;
    opened: boolean;
    closeOnClickOutside: boolean;
    cloneContent: boolean;
    buttonCaption: string;
}

export interface SnippetOffer {
    buttonCaption: string;
    tooltipText: string;
    totalCount: number;
    displaySize: DisplaySize;
    header: SnippetOfferHeader;
}

export interface SnippetOfferHeader {
    visible: boolean;
    body: string;
    image: string;
}

export interface SubscriptionConfirmation {
    offerId: string;
    fields: SubscriptionFieldValue[];
}

export interface VisitRequest {
    referrer: string;
    preview: boolean;
    userAgent: string;
    contact: ContactData;
}

export interface VisitValidation {
    salutation: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface ExternalPublicOffer extends PublicOffer {
    '@type': 'VoucherExternalOfferData';
    thumbImage: string;
    offerLink: string;
    showInFrame: boolean;
}

export interface OfferConfig {
    offerId: string;
    featured: boolean;
}

export interface PublicOffer {
    '@type': 'VoucherExternalOfferData' | 'VoucherSubscriptionData' | 'VoucherOfferData';
    id: string;
    name: string;
    offerType: OfferType;
    offerBody: string;
    headline: string;
    requestButtonText: string;
}

export interface SubscriptionPublicOffer extends PublicOffer {
    '@type': 'VoucherSubscriptionData';
    formFields: { [index: string]: PublicFormFieldConfig };
    revocationPolicyPageId: string;
    businessTermsPageId: string;
    privacyPolicyPageId: string;
    imprintPageId: string;
    thumbBody: string;
    thumbImage: string;
    offerImage: string;
    offerBusinessTermsConfirmText: string;
    logoImage: string;
    offerSubtitle: string;
    prefilledFieldsBehaviour: PrefilledFieldsBehaviour;
    upgradeSubscriptionPage: UpgradeSubscriptionPageData;
    ibanSubscriptionPage: IBANSubscriptionPageData;
    thankYouSubscriptionPage: ThankYouSubscriptionPageData;
    buttonText: string;
    buttonColor: string;
    offerBodyCollapsible: boolean;
    defaultDeliveryDate: number;
    earliestSelectableDeliveryDate: number;
}

export interface IBANSubscriptionPageData {
    onlyActiveOnUpgrade: boolean;
    headline: string;
    subline: string;
    image: string;
    activationText: string;
    description: string;
    descriptionCollapsible: boolean;
    buttonAddText: string;
    buttonColor: string;
}

export interface PublicFormFieldConfig {
    mandatory: boolean;
    description: string;
    cutFrom: number;
    customProperties: { [index: string]: string };
}

export interface ThankYouSubscriptionPageData {
    headline: string;
    image: string;
    description: string;
    buttonMoreVoucherText: string;
}

export interface UpgradeSubscriptionPageData {
    headline: string;
    subline: string;
    image: string;
    description: string;
    buttonUpgradeText: string;
    buttonCancelText: string;
    upgradeButtonColor: string;
    cancelButtonColor: string;
    cancelDescription: string;
    descriptionCollapsible: boolean;
}

export interface TeaserOffer {
    id: string;
    revision: number;
    name: string;
    offerType: OfferType;
    headline: TeaserHeadline;
    image: TeaserImage;
    text: TeaserText;
    ctaCaption: TeaserCtaCaption;
    benefit: TeaserBenefit;
    linkPath: string;
}

export interface TeaserBenefit {
    value: string;
    description: string;
}

export interface TeaserCtaCaption {
    normal: string;
    short: string;
}

export interface TeaserHeadline {
    normal: string;
    short: string;
}

export interface TeaserImage {
    horizontal: string;
    vertical: string;
}

export interface TeaserText {
    normal: string;
    short: string;
}

export interface VoucherPublicOffer extends PublicOffer {
    '@type': 'VoucherOfferData';
    offerImage: string;
    benefitDescription: string;
    benefitValue: string;
    redeemLink: string;
    logoImage: string;
    showInFrame: boolean;
    partnerName: string;
    advertiserId: string;
}

export interface SimpleAdvertiser extends Serializable {
    id: string;
    name: string;
}

export interface SimpleCampaign extends Serializable {
    id: string;
    name: string;
    partnerName: string;
}

export interface SimpleExternal extends Serializable {
    offerLink: string;
}

export interface SimpleOffer extends Serializable {
    id: string;
    type: OfferType;
    preview: boolean;
    active: boolean;
    campaign: SimpleCampaign;
    advertiser: SimpleAdvertiser;
    provision: number;
    ranking: number;
    headline: string;
    offerBody: string;
    start: Date;
    end: Date;
    limit: number;
    currentLimitation: number;
    voucher: SimpleVoucher;
    subscription: SimpleSubscription;
    external: SimpleExternal;
}

export interface SimpleSubscription extends Serializable {
    thumbBody: string;
    offerSubtitle: string;
}

export interface SimpleVoucher extends Serializable {
    voucherCode: string;
    benefitDescription: string;
    benefitValue: string;
    redeemLink: string;
}

export interface IdName {
    id: string;
    name: string;
}

export interface OfferClickedDataPoint {
    timeSlot: Date;
    snippets: { [index: string]: SnippetClicksWrapper };
}

export interface CampaignClicksWrapper {
    campaign: IdName;
    offerType: OfferType;
    clicks: number;
    details: { [index: string]: OfferClicksAggregation };
}

export interface OfferClicksAggregation {
    offer: IdName;
    clicks: number;
}

export interface SnippetClicksWrapper {
    snippet: IdName;
    publisher: IdName;
    campaigns: { [index: string]: CampaignClicksWrapper };
}

export interface OfferDeliveryDataPoint {
    timeSlot: Date;
    snippets: { [index: string]: SnippetDeliveryWrapper };
}

export interface CampaignDeliveryWrapper {
    campaign: IdName;
    offerType: OfferType;
    delivery: number;
    avgPosition: number;
    details: { [index: string]: OfferDeliveryAggregation };
}

export interface OfferDeliveryAggregation {
    offer: IdName;
    delivery: number;
    avgPosition: number;
}

export interface SnippetDeliveryWrapper {
    snippet: IdName;
    publisher: IdName;
    campaigns: { [index: string]: CampaignDeliveryWrapper };
}

export interface VisitorDataPoint {
    timeSlot: Date;
    snippets: { [index: string]: VisitorSnippetWrapper };
}

export interface VisitorAggregation {
    salutation: string;
    deviceType: DeviceType;
    total: number;
    clicks: number;
    externalDeals: number;
    subscriptionDeals: number;
    voucherPicks: number;
}

export interface VisitorSnippetWrapper {
    snippet: IdName;
    publisher: IdName;
    total: number;
    clicks: number;
    externalDeals: number;
    subscriptionDeals: number;
    voucherPicks: number;
    details: VisitorAggregation[];
}

export interface AssetReference extends Serializable {
    assetId: string;
    urlPath: string;
    resolution: Resolution;
    cropArea: CropArea;
    watermark: Watermark;
    previews: AssetPreviews;
}

export interface Auditor {
    id: string;
    name: string;
    email: string;
}

export interface FormFieldConfig {
    mandatory: boolean;
    description: string;
    cutFrom: number;
    customProperties: { [index: string]: string };
}

export interface Serializable {
}

export interface Resolution extends Serializable {
    width: number;
    height: number;
}

export interface CropArea {
    top: number;
    left: number;
    bottom: number;
    right: number;
}

export interface Watermark {
    url: string;
    x: string;
    y: string;
    opacity: number;
}

export interface AssetPreviews extends Serializable {
}

export type DeviceDetector =
    'APPLE_PHONE'
    | 'APPLE_TABLET'
    | 'ANDROID_PHONE'
    | 'ANDROID_TABLET'
    | 'WINDOWS_PHONE'
    | 'WINDOWS_TABLET'
    | 'OTHER_BLACKBERRY'
    | 'OTHER_OPERA'
    | 'OTHER_CHROME'
    | 'OTHER_FIREFOX'
    | 'DESKTOP'
    | 'UNKNOWN';

export type DeviceType = 'UNKNOWN' | 'NORMAL' | 'MOBILE' | 'TABLET';

export type DisplaySize = 'DEFAULT' | 'TINY';

export type FeedType = 'COUPON4U' | 'DPL';

export type StringFilterType = 'EQUALS' | 'STARTS_WITH' | 'CONTAINS';

export type PrefilledFieldsBehaviour = 'MUTABLE' | 'MUTABLE_ON_BUTTON_PRESS' | 'IMMUTABLE';

export type SnippetOfferDisplayMode = 'GRID' | 'CAROUSEL';

export type StatisticPeriod = 'LAST_DAY' | 'LAST_MONTH';

export type TrafficCategory = 'SHOP' | 'LEADGEN_PARTNER' | 'LEADGEN';

export type OfferType = 'VOUCHER' | 'SUBSCRIPTION' | 'EXTERNAL';

export type ContentType = 'REVOCATION' | 'BUSINESS_TERMS' | 'PRIVACY' | 'IMPRINT';

export type SubscriptionFormField =
    'SALUTATION'
    | 'FIRST_NAME'
    | 'LAST_NAME'
    | 'STREET'
    | 'STREET_NUMBER'
    | 'ADDRESS_SUPPLEMENTS'
    | 'ZIP_CODE'
    | 'CITY'
    | 'FILING_LOCATION'
    | 'PHONE'
    | 'EMAIL'
    | 'BIRTHDAY'
    | 'DELIVERYDAY'
    | 'INVOICE'
    | 'IBAN'
    | 'DEPOSITOR'
    | 'NATIONALITY'
    | 'RESIDENCE'
    | 'BIRTHPLACE'
    | 'CUSTOM_FIELD_1'
    | 'READ_MEDIUM'
    | 'CONFIRM_BUSINESS_TERMS'
    | 'CONFIRM_DIGITAL'
    | 'CONFIRM_NEWSLETTER'
    | 'PERMISSION_PHONE'
    | 'PERMISSION_EMAIL'
    | 'PRE_PERMISSION_TEXT'
    | 'POST_PERMISSION_TEXT';

export type PortalProgress =
    'CREATED'
    | 'INITIAL'
    | 'INVALID'
    | 'BUSINESS_TERMS'
    | 'BUSINESS_TERMS_ACCEPTED'
    | 'FINISHED';

export type FilterUnion = StringFilter | NumberFilter | BooleanFilter | DateFilter | NullFilter;

export type OfferDataUnion = VoucherOfferData | SubscriptionOfferData | ExternalOfferData;

export type OfferEditDataUnion = VoucherOfferEditData | SubscriptionOfferEditData | ExternalOfferEditData;

export type PublicOfferUnion = VoucherPublicOffer | SubscriptionPublicOffer | ExternalPublicOffer;
